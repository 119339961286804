import React from "react";
import { Helmet } from 'react-helmet'
import Layout from '../components/Layouts/Default'

import Seo from './../components/Seo'

function terms() {
  return (
    <Layout>
      <div className="terms basic">
        <Seo meta="terms" />
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <h3>{'Terms of Service'}</h3>
        <p>{`These Terms of Service ("Terms") govern your access to and use of the services, including our various websites, email notifications, applications, (the "Services" or Dr Lingua). Your access to and use of the Services are conditioned on your acceptance of and compliance with these Terms. By accessing or using the Services you agree to be bound by these Terms.`}</p>
        <h4>{`1. Basic Terms`}</h4>
        <p>{`The Services that Dr Lingua provides are always evolving and the form and nature of the Services that Dr Lingua provides may change from time to time without prior notice to you. In addition, Dr Lingua may stop (permanently or temporarily) providing the Services (or any features within the Services) to you or to users generally and may not be able to provide you with prior notice. We also retain the right to create limits on use and storage at our sole discretion at any time without prior notice to you.`}</p>
        <p>{`The Services may include advertisements, which may be targeted to the Content or information on the Services, queries made through the Services, or other information. The types and extent of advertising by Dr Lingua on the Services are subject to change. In consideration for Dr Lingua granting you access to and use of the Services, you agree that Dr Lingua and its third party providers and partners may place such advertising on the Services or in connection with the display of Content or information from the Services whether submitted by you or others.
          `}</p>
        <h4>{`2. Privacy`}</h4>
        <p>{`Any information that you provide to Dr Lingua is subject to our Privacy Policy, which governs our collection and use of your information. You understand that through your use of the Services you consent to the collection and use (as set forth in the Privacy Policy) of this information, including the transfer of this information to the United States and/or other countries for storage, processing and use by Dr Lingua. As part of providing you the Services, we may need to provide you with certain communications, such as service announcements and administrative messages. These communications are considered part of the Services and your Dr Lingua account, which you may not be able to opt-out from receiving.`}</p>
        <h4>{`3. Your License To Use the Services`}</h4>
        <p>{`Dr Lingua gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software that is provided to you by Dr Lingua as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by Dr Lingua, in the manner permitted by these Terms.`}</p>
        <h4>{`4. Dr Lingua Rights`}</h4>
        <p>{`All right, title, and interest in and to the Services are and will remain the exclusive property of Adrian Gray. The Services are protected by copyright, trademark, and other laws of both Australia and foreign countries. Nothing in the Terms gives you a right to use the Dr Lingua name or any of the Dr Lingua trademarks, logos, domain names, and other distinctive brand features. Any feedback, comments, or suggestions you may provide regarding Dr Lingua, or the Services is entirely voluntary and we will be free to use such feedback, comments or suggestions as we see fit and without any obligation to you.`}</p>
        <h4>{`5. Use of the Services`}</h4>
        <p>{`We reserve the right at all times (but will not have an obligation) to suspend or terminate users. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce the Terms, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, property or safety of Dr Lingua, its users and the public.`}</p>
        <p>{`You may not do any of the following while accessing or using the Services: (i) access, tamper with, or use non-public areas of the Services, Dr Lingua’s computer systems, or the technical delivery systems of Dr Lingua’s providers; (ii) probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; (iii) access or search or attempt to access or search the Services by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by Dr Lingua (and only pursuant to those terms and conditions), unless you have been specifically allowed to do so in a separate agreement with Dr Lingua (NOTE: crawling the Services is permissible if done in accordance with the provisions of the robots.txt file, however, scraping the Services without the prior consent of Dr Lingua is expressly prohibited); (iv) forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; or (v) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services.`}</p>
        <h4>{`6. Disclaimers and Limitations of Liability`}</h4>
        <p>{`Please read this section carefully since it limits the liability of Dr Lingua and its parents, subsidiaries, affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors (collectively, “Dr Lingua”). Each of the subsections below only applies up to the maximum extent permitted under applicable law. Some jurisdictions do not allow the disclaimer of implied warranties or the limitation of liability in contracts, and as a result the contents of this section may not apply to you. Nothing in this section is intended to limit any rights you may have which may not be lawfully limited.`}</p>
        <h5>{`A. The Services are Available "AS-IS"`}</h5>
        <p>{`Your access to and use of the Services or any Content are at your own risk. You understand and agree that the Services are provided to you on an "AS IS" and "AS AVAILABLE" basis. Without limiting the foregoing, to the maximum extent permitted under applicable law, DR LINGUA DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.`}</p>
        <p>{`Dr Lingua make no warranty and disclaim all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security or reliability of the Services or any Content; (ii) any harm to your computer system, loss of data, or other harm that results from your access to or use of the Services or any Content; (iii) the deletion of, or the failure to store or to transmit, any Content and other communications maintained by the Services; and (iv) whether the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. No advice or information, whether oral or written, obtained fromDr Lingua or through the Services, will create any warranty not expressly made herein.`}</p>
        <h5>{`B. Links`}</h5>
        <p>{`The Services may contain links to third-party websites or resources. You acknowledge and agree that Dr Lingua are not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement byDr Lingua of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources.`}</p>
        <h5>{`C. Limitation of Liability`}</h5>
        <p>{`TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DR LINGUA SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT.`}</p>
        <p>{`THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT DR LINGUA HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.`}</p>
        <h4>{`7. General Terms`}</h4>
        <h5>{`A. Waiver and Severability`}</h5>
        <p>{`The failure of Dr Lingua to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. In the event that any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.`}</p>
        <h5>{`B. Controlling Law and Jurisdiction`}</h5>
        <p>{`These Terms and any action related thereto will be governed by the laws of the State of New South Wales without regard to or application of its conflict of law provisions or your state or country of residence. All claims, legal proceedings or litigation arising in connection with the Services will be brought solely in the federal or state courts located in New South Wales, Australia, and you consent to the jurisdiction of and venue in such courts and waive any objection as to inconvenient forum.`}</p>
        <h5>{`C. Entire Agreement`}</h5>
        <p>{`These Terms, the Dr Lingua Rules and our Privacy Policy are the entire and exclusive agreement between Dr Lingua and you regarding the Services (excluding any services for which you have a separate agreement with Dr Lingua that is explicitly in addition or in place of these Terms), and these Terms supersede and replace any prior agreements between Dr Lingua and you regarding the Services. Other than members of the group of companies of which Dr Lingua, Inc. is the parent, no other person or company will be third party beneficiaries to the Terms.
          `}</p>
        <p>{`We may revise these Terms from time to time, the most current version will always be at drlingua.com/terms/. If the revision, in our sole discretion, is material we will notify you via an @AdHominum update or e-mail to the email associated with your account. By continuing to access or use the Services after those revisions become effective, you agree to be bound by the revised Terms.`}</p>
        <p>{`These Services are operated and provided by Adria Gray, 21 James St, Enmore, NSW, 2042, Australia. If you have any questions about these Terms, please contact us.`}</p>
        <p>{`Effective: September 29, 2017`}</p>
      </div>
    </Layout>
  )
}

export default terms;
